import { Col, Form, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useAtom } from "jotai";
import { useEffect, useMemo, useState } from "react";
import { CheckboxWithInput, EnterInput } from "../components/atomics/checkbox-with-input";
import { MachinegoDocumentUploader } from "../components/atomics/machinego-document-uploader";
import BigboxContainer from "../components/containers/bigbox-container";
import { BasicModel, DocumentCategory, DocumentModel, MachineDocumentViewModel } from "../service";
import { postloginMessageAtom } from "../store/global-atoms";
import { useSecureService } from "../store/service-atoms";
import { t } from "../translate";
import { EditMachinePartProps } from "../viewmodels/edit-page-part-props";

export type MachineAdditionalInfoPartProps = {
    rentalTypeCount?: (valueToAdd: number) => void;
    showRentalTypeErrorMessage?: boolean;
}

const MachineAdditionalInfoPart = (props: EditMachinePartProps & MachineAdditionalInfoPartProps) => {
    const { doGetRentalTypes, doGetCurrencies } = useSecureService();
    const [availableRentalTypes, setAvailableRentalTypes] = useState<BasicModel[] | undefined>(undefined);
    const [availableCurrencies, setAvailableCurrencies] = useState<BasicModel[] | undefined>(undefined);
    const [, setErrorMessage] = useAtom(postloginMessageAtom);
    useEffect(() => {
        const getServiceDatas = async () => {
            const result = await doGetRentalTypes();
            await sortRentalTypes(result);

            const currencies = await doGetCurrencies();
            setAvailableCurrencies(currencies);
        };
        const sortRentalTypes = async (result: any) => {
            if (result !== undefined) {
                const resultAscending = [...result].sort((a, b) => a.id! - b.id!);
                setAvailableRentalTypes(resultAscending);
            }
        };
        getServiceDatas();
        //eslint-disable-next-line
    }, []);

    const defaultDocs = useMemo(() => {
        return {
            insurance: props?.docs
                ?.filter((doc: MachineDocumentViewModel) => doc.documentCategory === "Insurance")
                .map((item) => item.document || {}),
            vehicleLicence: props?.docs
                ?.filter((doc: MachineDocumentViewModel) => doc.documentCategory === "VehicleLicense")
                .map((item) => item.document || {}),
            expertise: props?.docs
                ?.filter((doc: MachineDocumentViewModel) => doc.documentCategory === "Expertise")
                .map((item) => item.document || {}),
            periodicMaintenance: props?.docs
                ?.filter((doc: MachineDocumentViewModel) => doc.documentCategory === "PeriodicMaintenance")
                .map((item) => item.document || {}),
            ndt: props?.docs?.filter((doc: MachineDocumentViewModel) => doc.documentCategory === "NDT").map((item) => item.document || {}),
            operatorLicences: props?.docs
                ?.filter((doc: MachineDocumentViewModel) => doc.documentCategory === "OperatorLicenses")
                .map((item) => item.document || {}),
            otherFiles: props?.docs
                ?.filter((doc: MachineDocumentViewModel) => doc.documentCategory === "Other")
                .map((item) => item.document || {}),
        };
    }, [props?.docs]);

    const handleUploadChange = (data: DocumentModel[], type: DocumentCategory) => {
        props.uploadChange && props.uploadChange(data, type);
    };

    const checkBoxChecked = (checked: boolean, inputName: string, selectName?: string) => {
        if(inputName && selectName && !checked) {
            props.form.setFieldsValue(
                {
                    [inputName]: "",
                    [selectName]: ""
                }
            );
        }
        if(props.rentalTypeCount) {
            if(checked) props.rentalTypeCount(1);
            else props.rentalTypeCount(-1);
        }
    }

    return (
        <BigboxContainer title={t("machines.add.additionalInfoTitle")}>
            <Form.Item required label={t("machines.add.AdLabel")} name={"rentalAdTitle"}>
                <TextArea autoSize={{ minRows: 5, maxRows: 10 }}  />
            </Form.Item>
            <Form.Item label={t("machines.add.notesLabel")} name="notes">
                <TextArea autoSize={{ minRows: 5, maxRows: 10 }}  />
            </Form.Item>
            <h4>{t("machines.add.rentalTypesTitle")}</h4>
            <Row justify="space-between" gutter={[16, 16]}>
                {availableRentalTypes?.map((rtypes) => {
                    return (
                        <CheckboxWithInput
                            form={props.form}
                            xs={12}
                            checkboxLabel={rtypes.name}
                            checkboxName={rtypes.name ? "check" + rtypes.name : "check" + availableRentalTypes.indexOf(rtypes)}
                            inputLabel={t("machines.add-info.price")}
                            help={t("common.generic-required-currency-message")}
                            inputName={rtypes.name ? "input" + rtypes.name : "input" + availableRentalTypes.indexOf(rtypes)}
                            isSuffixDropdown={true}
                            suffixProps={{
                                selectName: "select-" + rtypes.name,
                                items: availableCurrencies,
                                selectedItem: props.form.getFieldValue("select" + rtypes.name),
                            }}
                            checkBoxChecked={checkBoxChecked}
                            enterType={EnterInput.Price}
                        />
                    );
                })}
            </Row>
            {props.showRentalTypeErrorMessage &&
                <span style={{paddingTop: '0.25rem', color: '#ee3031'}}>{t("machines.add.rentalTypeErrorMessage")}</span>
            } 
            <h4 style={{ marginTop: "1rem" }}>{t("machines.add.documentsTitle")}</h4>
            <Row gutter={[16, 16]}>
                <Col xs={24} md={12}>
                    <MachinegoDocumentUploader
                        onFilesChange={handleUploadChange}
                        defaultDocumentFiles={defaultDocs.insurance}
                        title={t("machines.add-info.insurance")}
                        docType="Insurance"
                        unsupportedFileError={() =>
                            setErrorMessage({
                                message: t("common.unsupportedFile"),
                                type: "error",
                            })
                        }
                        multiple={false}
                    />
                </Col>
                <Col xs={24} md={12}>
                    <MachinegoDocumentUploader
                        onFilesChange={handleUploadChange}
                        defaultDocumentFiles={defaultDocs.vehicleLicence}
                        title={t("machines.add-info.vehicleLicense")}
                        docType="VehicleLicense"
                        unsupportedFileError={() =>
                            setErrorMessage({
                                message: t("common.unsupportedFile"),
                                type: "error",
                            })
                        }
                        multiple={false}
                    />
                </Col>
                <Col xs={24} md={12}>
                    <MachinegoDocumentUploader
                        onFilesChange={handleUploadChange}
                        defaultDocumentFiles={defaultDocs.expertise}
                        title={t("machines.add-info.expertise")}
                        docType="Expertise"
                        unsupportedFileError={() =>
                            setErrorMessage({
                                message: t("common.unsupportedFile"),
                                type: "error",
                            })
                        }
                        multiple={false}
                    />
                </Col>
                <Col xs={24} md={12}>
                    <MachinegoDocumentUploader
                        onFilesChange={handleUploadChange}
                        defaultDocumentFiles={defaultDocs.periodicMaintenance}
                        title={t("machines.add-info.periodicMaintenance")}
                        docType="PeriodicMaintenance"
                        unsupportedFileError={() =>
                            setErrorMessage({
                                message: t("common.unsupportedFile"),
                                type: "error",
                            })
                        }
                        multiple={false}
                    />
                </Col>
                <Col xs={24} md={12}>
                    <MachinegoDocumentUploader
                        onFilesChange={handleUploadChange}
                        defaultDocumentFiles={defaultDocs.ndt}
                        title={t("machines.add-info.nDT")}
                        docType="NDT"
                        unsupportedFileError={() =>
                            setErrorMessage({
                                message: t("common.unsupportedFile"),
                                type: "error",
                            })
                        }
                        multiple={false}
                    />
                </Col>
                <Col xs={24} md={12}>
                    <MachinegoDocumentUploader
                        onFilesChange={handleUploadChange}
                        defaultDocumentFiles={defaultDocs.operatorLicences}
                        title={t("machines.add-info.operatorLicenses")}
                        docType="OperatorLicenses"
                        unsupportedFileError={() =>
                            setErrorMessage({
                                message: t("common.unsupportedFile"),
                                type: "error",
                            })
                        }
                        multiple={true}
                        maxCount={5}
                    />
                </Col>
                <Col xs={24} md={24}>
                    <MachinegoDocumentUploader
                        onFilesChange={handleUploadChange}
                        defaultDocumentFiles={defaultDocs.otherFiles}
                        title={t("machines.add-info.other")}
                        docType="Other"
                        unsupportedFileError={() =>
                            setErrorMessage({
                                message: t("common.unsupportedFile"),
                                type: "error",
                            })
                        }
                        multiple={true}
                        maxCount={5}
                    />
                </Col>
            </Row>
        </BigboxContainer>
    );
};

export default MachineAdditionalInfoPart;
