import OnboardLayout from "../../components/layouts/onboard-layout";
import { useLayoutEffect, useState } from 'react';
import Markdown from 'markdown-to-jsx';

type AgreementProps = {
    type: 'PrivacyPolicy' | 'UserAgreement' | 'ConsentApprovalText'
}

const Agreement = (props: AgreementProps) => {

    const [agreementText, setAgreementText] = useState<string>('');

    useLayoutEffect(() => {
        switch (props.type) {
            case 'PrivacyPolicy': 
                import('../../markdown/agreements/tr/aydinlatma-metni.md').then(res => {
                    fetch(res.default).then((response) => response.text()).then((text) => {
                        setAgreementText(text)
                    })
                })
            break;
            case 'UserAgreement': 
            import('../../markdown/agreements/tr/kullanici-sozlesmesi.md').then(res => {
                fetch(res.default).then((response) => response.text()).then((text) => {
                    setAgreementText(text)
                })
            })
            break;
            case 'ConsentApprovalText': 
                import('../../markdown/agreements/tr/kurumsal-ve-bireysel-uye-acik-riza-metni.md').then(res => {
                    fetch(res.default).then((response) => response.text()).then((text) => {
                        setAgreementText(text)
                    })
                })
            break;
        }
    }, [props.type]);

    return (
        <OnboardLayout>
            <Markdown>{agreementText}</Markdown>
        </OnboardLayout>
    )
}

export default Agreement; 