import { Col, Form, message, Row } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useAtom } from "jotai";
import PrimaryButton from "../../../components/atomics/primary-button";
import SecureLayout from "../../../components/layouts/secure-layout";
import { useQuery } from "../../../helpers/use-query";
import MachineAdditionalInfoPart from "../../../page-parts/machine-additional-info-part";
import MachineAdditionalServicesPart from "../../../page-parts/additional-services-part";
import MachineMachineInfoPart from "../../../page-parts/machine-machine-info-part";
import MachineServiceRegionsPart from "../../../page-parts/machine-service-regions-part";
import { extendedMachineDetailAtom } from "../../../store/global-atoms";
import { t } from "../../../translate";
import { useSecureService } from "../../../store/service-atoms";
import { BasicModel, DocumentCategory, DocumentModel, MachineDocumentViewModel, MachineRentalTypeModel } from "../../../service";
import { useNavigate } from "react-router-dom";

const AddMachine = () => {
    const query = useQuery();
    const companyId = query.get("compid");
    const isMachineForSale = query.get("for-sale");
    const [form] = Form.useForm();
    const [editedMachine, editMachine] = useAtom(extendedMachineDetailAtom);
    const { doGetRentalTypes, doGetCurrencies } = useSecureService();
    const [availableCurrencies, setAvailableCurrencies] = useState<BasicModel[] | undefined>(undefined);
    const [availableRentalTypes, setAvailableRentalTypes] = useState<BasicModel[] | undefined>(undefined);
    const [docs, setDocs] = useState<MachineDocumentViewModel[]>([]);
    const [selectedRentalTypeCounter, setSelectedRentalTypeCounter] = useState<number>(0);
    const [showRentalTypeErrorMessage, setShowRentalTypeErrorMessage] = useState<boolean>(false);
    const [dataFetching, setDataFetching] = useState(true);
    const navigate = useNavigate();

    const pics = useMemo(() => {
        return editedMachine?.documents?.filter((doc) => doc.documentCategory === "Photo");
    }, [editedMachine?.documents]);
    const otherDocs = useMemo(() => {
        if (docs) {
            return docs?.filter((doc) => doc.documentCategory !== "Photo");
        }
        return editedMachine?.documents?.filter((doc) => doc.documentCategory !== "Photo");
    }, [editedMachine?.documents, docs]);

    useEffect(() => {
        if (editedMachine) {
            setDocs(editedMachine.documents ?? []);
        }
        editMachine((mach) => {
            return {
                ...mach,
                lessorCompanyId: companyId ? parseInt(companyId) : undefined,
                id: undefined,
            };
        });
        const getRentalTypes = async () => {
            const result = await doGetRentalTypes();
            setAvailableRentalTypes(result);
        };

        const getCurrencies = async () => {
            const currencies = await doGetCurrencies();
            setAvailableCurrencies(currencies);
        };

        getRentalTypes();
        getCurrencies();

        if (editedMachine) {
            editedMachine.rentalTypes?.forEach(e => {
                if (e.rentalType) {
                    rentalTypeCount(1);
                }
            })
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (availableCurrencies && availableRentalTypes) {
            setDataFetching(false);
        }
    }, [availableCurrencies, availableRentalTypes]);

    const checkFields = async (): Promise<boolean> => {
        var rentalTitleCheck = await form.getFieldValue(['rentalAdTitle']);
        if (rentalTitleCheck === null || rentalTitleCheck === ""){
            message.error(t("machines.add.AdLabel-error"));
            return false
        }

        return true;
    }

    const onMachineFormFinish = async (params: any) => {
        if ( !(await checkFields()) ) {
            return;
        }
        if (selectedRentalTypeCounter < 1 && isMachineForSale !== "true") {
            setShowRentalTypeErrorMessage(true);
            return;
        }

        let rentalTypes: MachineRentalTypeModel[] = [];
        editMachine((oldVal) => {
            return {}
        });
        availableRentalTypes?.forEach((rtype) => {
            if (params["check" + rtype.name] && params["input" + rtype.name]) {
                let rental: MachineRentalTypeModel = {
                    currency: availableCurrencies?.find((x) => x.name === params["select-" + rtype.name]),
                    price: params["input" + rtype.name],
                    rentalType: {
                        id: rtype.id,
                        name: rtype.name,
                    },
                };
                rentalTypes.push(rental);
            }
        });
        console.log('ilan:' + form.getFieldValue('rentalAdTitle'))
        editMachine((oldVal) => {
            return {
                ...oldVal,
                ...params,
                rentalTypes: rentalTypes,
                documents: docs,
            };
        });

        navigate("/machines/review");
    };

    const uploadedFilesChanged = (docs: DocumentModel[], category: DocumentCategory, remove?: boolean) => {
        console.log('docs:' + JSON.stringify(docs));
        setDocs((oldVal) => {
            var newCatDoc = oldVal?.filter((doc) => {
                return doc.documentCategory !== category;
            });
            if (!newCatDoc) newCatDoc = [];
            var addedDocs: MachineDocumentViewModel[] = docs.map((doc) => {
                return {
                    documentCategory: category,
                    document: doc,
                };
            });
            newCatDoc?.push(...addedDocs);

            return [...newCatDoc];
        });
    };

    const rentalTypeCount = (valueToAdd: number) => {
        if (selectedRentalTypeCounter + valueToAdd > 0) setShowRentalTypeErrorMessage(false);
        setSelectedRentalTypeCounter((oldVal) => {
            return oldVal + valueToAdd;
        });
    };

    return (
        <SecureLayout loading={dataFetching} activePage={"machines"} role={"lessor"} title={t("machines.add.title")} description={t("machines.add.description")}>
            <Form name="add-machine" initialValues={editedMachine} form={form} onFinish={onMachineFormFinish} layout="vertical">
                <Row gutter={[4, 4]}>
                    <Col xs={24} md={12}>
                        <MachineMachineInfoPart uploadChange={uploadedFilesChanged} docs={pics} form={form} />
                    </Col>

                    <Col xs={24} md={12}>
                        <MachineAdditionalInfoPart
                            rentalTypeCount={rentalTypeCount}
                            showRentalTypeErrorMessage={showRentalTypeErrorMessage}
                            uploadChange={uploadedFilesChanged}
                            docs={otherDocs}
                            form={form}
                        />
                    </Col>

                    <Col xs={24} md={12}>
                        <MachineAdditionalServicesPart form={form} />
                    </Col>

                    <Col xs={24} md={12}>
                        <MachineServiceRegionsPart />
                    </Col>
                    <Col xs={6} offset={9}>
                        <Form.Item>
                            <PrimaryButton htmlType="submit" style={{ marginTop: "1rem" }}>
                                {t("common.continue")}
                            </PrimaryButton>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </SecureLayout>
    );
};

export default AddMachine;
