import { useAtom } from "jotai";
import { useGoogleReCaptcha } from "react-google-recaptcha-hook";
import {
    ActivationModel,
    ActivationWithUpdateModel,
    AttachmentApi,
    BasicModel,
    BillingTimeOptionApi,
    BrandApi,
    CameraResolutionApi,
    CategoryApi,
    CategoryModel,
    CityApi,
    CityModel,
    CurrencyApi,
    ElevatorTypeApi,
    FuelTypeApi,
    Int32ValidatedModel,
    LessorCompanyAgreementsViewModel,
    LessorCompanyApi,
    LessorCompanyCreateModel,
    LessorCompanyInfoUpdateModel,
    LessorCompanyPaymentInfoViewModel,
    LessorCompanyProfileCreateModel,
    LessorUserCreateViewModel,
    LoginRequestModel,
    MachineApi,
    MachineApprovalModel,
    MachineCommissionApi,
    MachineCreateModel,
    MachineCreateSaleModel,
    MachineDetailModel,
    MachineDetailModelPagedDataModel,
    MachineEditModel,
    MachineSaleListModelPagedDataModel,
    MachineSearchModel,
    MaturityOptionApi,
    Middleware,
    NotificationApi,
    OfferFormApi,
    OfferFormEditModel,
    OfferFormSearchModel,
    PaymentPeriodApi,
    PaymentTypeApi,
    RentalTypeApi,
    RenterCompanyAgreementsViewModel,
    RenterCompanyApi,
    RenterCompanyCreateModel,
    RenterCompanyPaymentUpdateModel,
    RenterCompanyProfileCreateModel,
    RenterUserCreateModel,
    RequestFormApi,
    RequestFormCreateModel,
    RequestFormCreateWithRegisterModel,
    RequestFormEditModel,
    RequestFormSearchModel,
    ResetPasswordViewModel,
    ResponseContext,
    SetPasswordModel,
    ViewApi,
} from "../service";
import { ExtendedMachineDetailModel, SearchMachineModel } from "../service/models/ExtendedMachineDetailModel";
import { t } from "../translate";
import { activeRecaptcha } from "./app-constants";
import {
    ModifiedRequestDetailModel,
    RoleType,
    lessorPurposeAtom,
    loadingAtom,
    postloginMessageAtom,
    roleAtom,
    unauthorizedResponseAtom,
    userAtom,
} from "./global-atoms";

const baseCategoryApi = new CategoryApi();
const baseFuelTypesApi = new FuelTypeApi();
const baseElevatorTypesApi = new ElevatorTypeApi();
const baseCameraResolutionApi = new CameraResolutionApi();
const baseCitiesApi = new CityApi();
const baseBrandApi = new BrandApi();
const baseAttachmentApi = new AttachmentApi();
const baseRentalTypesApi = new RentalTypeApi();
const baseCurrenciesApi = new CurrencyApi();
const baseMachineApi = new MachineApi();
const baseRenterCompanyApi = new RenterCompanyApi();
const baseLessorCompanyapi = new LessorCompanyApi();
const baseRequestFormApi = new RequestFormApi();
const baseOfferFormApi = new OfferFormApi();
const baseMaturityOptionsApi = new MaturityOptionApi();
const basePaymentTypesApi = new PaymentTypeApi();
const baseNotificationApi = new NotificationApi();
const baseBillingTimeOptionApi = new BillingTimeOptionApi();
const basePaymentPeriodApi = new PaymentPeriodApi();
const machineCommissionApi = new MachineCommissionApi();
const baseViewApi = new ViewApi();

const postConfig = (prelogin?: boolean, additionalHeaders?: Record<string, string>) => {
    const headers = {
        "Content-Type": "application/json",
        ...additionalHeaders,
    };

    const initObj = getModeAndCredentialsHeader(prelogin);
    initObj.headers = headers;
    return initObj;
};

const getConfig = (prelogin?: boolean, additionalHeaders?: Record<string, string>) => {
    const initObj = getModeAndCredentialsHeader(prelogin);
    initObj.headers = { ...additionalHeaders };
    return initObj;
};

const getModeAndCredentialsHeader = (prelogin?: boolean) => {
    const initObj: RequestInit = {
        mode: "cors",
    };
    if (!prelogin) initObj.credentials = "include";
    return initObj;
};

export type PaginationType = {
    current: undefined | number;
    pageSize: undefined | number;
};

let allElevatorTypes: BasicModel[] | undefined = undefined;
let allFuelTypes: BasicModel[] | undefined = undefined;
let allCameraResolutionTypes: BasicModel[] | undefined = undefined;
let allCategoryModels: CategoryModel[] | undefined = undefined;
let allCities: CityModel[] | undefined = undefined;
let allExactCities: CityModel[] | undefined = undefined;
let allRentalTypes: BasicModel[] | undefined = undefined;
let allCurrencies: BasicModel[] | undefined = undefined;
let allAttachments: BasicModel[] | undefined = undefined;
let allBrands: BasicModel[] | undefined = undefined;
let allPaymentTypes: BasicModel[] | undefined = undefined;
let allMaturityOptions: BasicModel[] | undefined = undefined;

export const findCategory = (subCatId: number | undefined) => {
    if (!allCategoryModels || !subCatId) return undefined;
    let targetCategory: CategoryModel | undefined = undefined;
    for (let i = 0; i < allCategoryModels.length; i++) {
        let sub = allCategoryModels[i].subCategories?.find((sub) => {
            return sub.id === subCatId;
        });
        if (sub) {
            targetCategory = allCategoryModels[i];
            break;
        }
    }
    return targetCategory;
};

type RecaptchaActionType =
    | "guest"
    | "contactform"
    | "rentersignup"
    | "rentersignin"
    | "renterforgotpassword"
    | "renteractivate"
    | "renterresendactivate"
    | "renterresetpassword"
    | "renterrequestform"
    | "lessorsignup"
    | "lessorsignin"
    | "lessorforgotpassword"
    | "lessoractivate"
    | "lessorresendactivate"
    | "lessorresetpassword"
    | "adminsignin"
    | "adminforgotpassword"
    | "adminresetpassword"
    | "pageview";

export const useSecureService = () => {
    const [, setLoading] = useAtom(loadingAtom);
    const [, setUser] = useAtom(userAtom);
    const [, setLessorPurpose] = useAtom(lessorPurposeAtom);
    const [role, setRole] = useAtom(roleAtom);
    const [, setErrorMessage] = useAtom(postloginMessageAtom);
    const [, setUnauthorizedFired] = useAtom(unauthorizedResponseAtom);
    const { executeGoogleReCaptcha } = useGoogleReCaptcha(activeRecaptcha);

    const generateRecaptcha = async (actionType: RecaptchaActionType) => {
        const token = await executeGoogleReCaptcha(actionType);
        const record = { "x-recaptcha-token": token };
        return record;
    };

    const handleSecureCatchErrors = async (err: any) => {
        try {
            const x = await err.json();
            setErrorMessage({ message: x.title, type: "error" });
            return;
        } catch {}

        if (err?.status === 401) {
            console.log("DEBUG: ", err.status);
            setUser(undefined);
            setLessorPurpose(undefined);
            setRole(undefined);
            setErrorMessage({ message: t("common.401"), type: "error" });
            setUnauthorizedFired(err && err.url ? err.url : "unauthorized");
        } else if (err?.status === 500) {
            setErrorMessage({
                message: t("common.genericError"),
                type: "error",
            });
        } else {
            const msg = await err.json();
            if (typeof msg === "string") {
                setErrorMessage({ message: msg.toString(), type: "error" });
            } else if (msg.title !== undefined) {
                setErrorMessage({ message: msg.title, type: "error" });
            } else {
                setErrorMessage({
                    message: t("common.genericError"),
                    type: "error",
                });
            }
        }
    };

    const handleParameterErrors = async (missingParams: string[]) => {
        setErrorMessage({ message: missingParams.toString(), type: "error" });
    };

    const baseMiddleware: Middleware = {
        pre: async (params) => {
            //console.log(":::REQUEST SENT:::");
            //console.log(params);
            setLoading(true);
        },
        post: async (params: ResponseContext) => {
            //console.log(params);
            //console.log(":::RESPONSE RECEIVED:::");
            if (!params.response.ok) handleSecureCatchErrors(params.response);
            setLoading(false);
        },
    };

    const lessorCompanyApi = baseLessorCompanyapi.withMiddleware(baseMiddleware);
    const renterCompanyApi = baseRenterCompanyApi.withMiddleware(baseMiddleware);
    const machineApi = baseMachineApi.withMiddleware(baseMiddleware);
    const citiesApi = baseCitiesApi.withMiddleware(baseMiddleware);
    const requestFormApi = baseRequestFormApi.withMiddleware(baseMiddleware);
    const offerFormApi = baseOfferFormApi.withMiddleware(baseMiddleware);
    const paymentTypeApi = basePaymentTypesApi.withMiddleware(baseMiddleware);
    const maturityOptionApi = baseMaturityOptionsApi.withMiddleware(baseMiddleware);
    const billingTimeOptionApi = baseBillingTimeOptionApi.withMiddleware(baseMiddleware);
    const paymentPeriodApi = basePaymentPeriodApi.withMiddleware(baseMiddleware);
    const categoryApi = baseCategoryApi.withMiddleware(baseMiddleware);
    const fuelTypesApi = baseFuelTypesApi.withMiddleware(baseMiddleware);
    const cameraResolutionApi = baseCameraResolutionApi.withMiddleware(baseMiddleware);
    const elevatorTypesApi = baseElevatorTypesApi.withMiddleware(baseMiddleware);
    const rentalTypesApi = baseRentalTypesApi.withMiddleware(baseMiddleware);
    const currenciesApi = baseCurrenciesApi.withMiddleware(baseMiddleware);
    const attachmentApi = baseAttachmentApi.withMiddleware(baseMiddleware);
    const brandApi = baseBrandApi.withMiddleware(baseMiddleware);
    const notificationApi = baseNotificationApi.withMiddleware(baseMiddleware);
    const viewApi = baseViewApi.withMiddleware(baseMiddleware);

    const doLogin = async (role: RoleType, model: LoginRequestModel) => {
        try {
            const recaptchaHeader = await generateRecaptcha(role === "lessor" ? "lessorsignin" : "rentersignin");
            console.log("recaptchaHeader: ", recaptchaHeader);
            if (!recaptchaHeader) return false;
            const result =
                role !== "lessor"
                    ? await renterCompanyApi.renterCompanySignInPost({ loginRequestModel: model }, postConfig(false, recaptchaHeader))
                    : await lessorCompanyApi.lessorCompanySignInPost({ loginRequestModel: model }, postConfig(false, recaptchaHeader));
            setUser(result);
            setLessorPurpose(result.lessorPurpose);
            setRole(result.role === "Lessor" ? "lessor" : "customer");
            return true;
        } catch (err: any) {
            setUser(undefined);
            setLessorPurpose(undefined);
            setRole(undefined);
            return false;
        }
    };

    const doGetUserDetails = async () => {
        try {
            if (role === undefined) {
                return false;
            }
            const result =
                role !== "lessor"
                    ? await renterCompanyApi.renterCompanyUserDetailGet(getConfig())
                    : await lessorCompanyApi.lessorCompanyUserDetailGet(getConfig());
            console.log("doGetUserDetails: ", result);
            setUser(result);
            setRole(result.role === "Lessor" ? "lessor" : "customer");
            return result;
        } catch (err: any) {
            setUser(undefined);
            setLessorPurpose(undefined);
            setRole(undefined);
            return false;
        }
    };

    const doLessorSignup = async (model: LessorCompanyCreateModel) => {
        try {
            const recaptcha = await generateRecaptcha("lessorsignup");
            if (!recaptcha) return undefined;
            const result = await lessorCompanyApi.lessorCompanySignUpPost(
                { lessorCompanyCreateModel: model },
                postConfig(false, recaptcha)
            );
            return result.data;
        } catch (err: any) {
            return undefined;
        }
    };

    const doRenterSignup = async (model: RenterCompanyCreateModel) => {
        try {
            const recaptcha = await generateRecaptcha("rentersignup");
            if (!recaptcha) return undefined;
            const result = await renterCompanyApi.renterCompanySignUpPost(
                { renterCompanyCreateModel: model },
                postConfig(false, recaptcha)
            );
            return result.data;
        } catch (err: any) {
            return undefined;
        }
    };

    const doResendActivationCode = async (role: RoleType, userId: number) => {
        try {
            const recaptchaHeader = await generateRecaptcha(role === "lessor" ? "lessorresendactivate" : "renterresendactivate");
            if (!recaptchaHeader) return false;
            if (role === "lessor")
                await lessorCompanyApi.lessorCompanyResendActivationCodeUserIdGet({ userId }, getConfig(true, recaptchaHeader));
            else await renterCompanyApi.renterCompanyResendActivationCodeUserIdGet({ userId }, getConfig(true, recaptchaHeader));
        } catch (err: any) {}
    };

    const doActivate = async (role: RoleType, activationModel: ActivationModel) => {
        try {
            const recaptchaHeader = await generateRecaptcha(role === "lessor" ? "lessoractivate" : "renteractivate");
            if (!recaptchaHeader) return false;
            if (role === "lessor")
                await lessorCompanyApi.lessorCompanyActivatePost({ activationModel }, postConfig(undefined, recaptchaHeader));
            else await renterCompanyApi.renterCompanyActivatePost({ activationModel }, postConfig(undefined, recaptchaHeader));
            return true;
        } catch (err: any) {
            return false;
        }
    };

    const doForgotPassword = async (role: RoleType, phone: string) => {
        try {
            let result: Int32ValidatedModel | undefined = undefined;
            const recaptchaHeader = await generateRecaptcha(role === "lessor" ? "lessorforgotpassword" : "renterforgotpassword");
            console.log(recaptchaHeader);

            if (!recaptchaHeader) return false;

            if (role === "lessor")
                result = await lessorCompanyApi.lessorCompanyForgotPasswordPost(
                    { forgotPasswordViewModel: { phone: phone } },
                    postConfig(true, recaptchaHeader)
                );
            else
                result = await renterCompanyApi.renterCompanyForgotPasswordPost(
                    { forgotPasswordViewModel: { phone: phone } },
                    postConfig(true, recaptchaHeader)
                );
            return result.data;
        } catch (err: any) {
            return undefined;
        }
    };

    const doResetPassword = async (role: RoleType, resetPasswordViewModel: ResetPasswordViewModel) => {
        try {
            const recaptchaHeader = await generateRecaptcha(role === "lessor" ? "lessorresetpassword" : "renterresetpassword");
            if (!recaptchaHeader) return false;
            if (role === "lessor")
                await lessorCompanyApi.lessorCompanyResetPasswordPost({ resetPasswordViewModel }, postConfig(true, recaptchaHeader));
            else await renterCompanyApi.renterCompanyResetPasswordPost({ resetPasswordViewModel }, postConfig(true, recaptchaHeader));
            setErrorMessage({
                message: "Şifre başarıyla degistirildi",
                type: "success",
            });
            return true;
        } catch (err: any) {
            return false;
        }
    };

    const doGetMachineSubcategory = async () => {
        if (allCategoryModels) return allCategoryModels;
        try {
            const recaptcha = await generateRecaptcha("guest");
            if (!recaptcha) return undefined;
            allCategoryModels = await categoryApi.categoryWithChildrenGet(getConfig(false, recaptcha));
            return allCategoryModels;
        } catch (err: any) {
            return undefined;
        }
    };

    const doGetFuelTypes = async () => {
        if (allFuelTypes) return allFuelTypes;
        try {
            const recaptcha = await generateRecaptcha("guest");
            if (!recaptcha) return undefined;
            allFuelTypes = await fuelTypesApi.fuelTypeGet(getConfig(false, recaptcha));
            return allFuelTypes;
        } catch (err: any) {
            return undefined;
        }
    };

    const doGetCameraResolutionTypes = async () => {
        if (allCameraResolutionTypes) return allCameraResolutionTypes;
        try {
            const recaptcha = await generateRecaptcha("guest");
            if (!recaptcha) return undefined;
            allCameraResolutionTypes = await cameraResolutionApi.cameraResolutionGet(getConfig(false, recaptcha));
            return allCameraResolutionTypes;
        } catch (err: any) {
            return undefined;
        }
    };

    const doGetElevatorTypes = async () => {
        if (allElevatorTypes) return allElevatorTypes;
        try {
            const recaptcha = await generateRecaptcha("guest");
            if (!recaptcha) return undefined;
            allElevatorTypes = await elevatorTypesApi.elevatorTypeGet(getConfig(false, recaptcha));
            return allElevatorTypes;
        } catch (err: any) {
            return undefined;
        }
    };

    const doGetRentalTypes = async () => {
        if (allRentalTypes) return allRentalTypes;
        try {
            const recaptcha = await generateRecaptcha("guest");
            if (!recaptcha) return undefined;
            allRentalTypes = await rentalTypesApi.rentalTypeGet(getConfig(false, recaptcha));
            return allRentalTypes;
        } catch (err: any) {
            return undefined;
        }
    };

    const doGetCurrencies = async () => {
        if (allCurrencies) return allCurrencies;
        try {
            const recaptcha = await generateRecaptcha("guest");
            if (!recaptcha) return undefined;
            allCurrencies = await currenciesApi.currencyGet(getConfig(false, recaptcha));
            return allCurrencies;
        } catch (err: any) {
            return undefined;
        }
    };

    const doGetCities = async () => {
        if (allCities) return allCities;
        try {
            const recaptcha = await generateRecaptcha("guest");
            if (!recaptcha) return undefined;
            allCities = await citiesApi.cityGet(getConfig(false, recaptcha));
            return allCities;
        } catch {
            return undefined;
        }
    };

    const doGetExactCities = async () => {
        if (allExactCities) return allExactCities;
        try {
            const recaptcha = await generateRecaptcha("guest");
            if (!recaptcha) return undefined;
            allExactCities = await citiesApi.cityExactGet(getConfig(false, recaptcha));
            return allExactCities;
        } catch {
            return undefined;
        }
    };

    const doGetAttachments = async (id: number) => {
        try {
            const recaptcha = await generateRecaptcha("guest");
            if (!recaptcha) return undefined;
            const result = await attachmentApi.attachmentSubCategoryIdGet({ subCategoryId: id }, getConfig(false, recaptcha));
            allAttachments = result;
            return result;
        } catch (err: any) {
            return undefined;
        }
    };

    const doGetBrandsByCategory = async (catId: number) => {
        try {
            const recaptcha = await generateRecaptcha("guest");
            if (!recaptcha) return undefined;
            const brands = await brandApi.brandCategoryIdGet({ categoryId: catId }, getConfig(false, recaptcha));
            allBrands = brands;
            return brands;
        } catch (err: any) {
            return undefined;
        }
    };

    // machineId is used for already exist machine. For instance, When creating a sale for a machine that already exist in machine table
    const doPostCreateMachine = async (model: ExtendedMachineDetailModel, createCount: number, machineId?: number) => {
        let createModel: MachineCreateModel = {};
        let attachments: number[] = [];
        model.attachmentsList?.forEach((item) => {
            const atc: BasicModel | undefined = allAttachments?.find((at) => at.name === item);
            if (atc && atc.id) attachments.push(atc.id);
        });
        createModel.attachments = attachments;
        createModel.liftingHeight = model.liftingHeight;
        createModel.machineId = machineId;
        createModel.attachmentOther = model.otherAttachmentsName;
        createModel.bladeWidth = model.bladeWidth;
        createModel.bomLength = model.bomLength;
        createModel.brandId = allBrands?.find((brn) => brn.name === model.brandName)?.id;
        createModel.brandOther = model.otherBrandName;
        createModel.breakingInfo = model.breakingInfo;
        createModel.cameraResolutionId = allCameraResolutionTypes?.find((crt) => crt.name === model.cameraResolutionName)?.id;
        createModel.carryingCapacity = model.carryingCapacity;
        createModel.cleaningCapacity = model.cleaningCapacity;
        createModel.compressionWidth = model.compressionWidth;
        createModel.concretePumpingCapacity = model.concretePumpingCapacity;
        createModel.drivingEquipment = model.drivingEquipment;
        createModel.elevatorTypeId = allElevatorTypes?.find((elt) => elt.name === model.elevatorTypeName)?.id;
        createModel.engineBrand = model.engineBrand;
        createModel.enginePower = model.enginePower;
        createModel.flightDistance = model.flightDistance;
        createModel.flightTime = model.flightTime;
        createModel.floorCount = model.floorCount;
        createModel.freeAirOutput = model.freeAirOutput;
        createModel.frequency = model.frequency;
        createModel.fuelIncluded = model.fuelIncluded;
        createModel.fuelTypeId = allFuelTypes?.find((fty) => fty.name === model.fuelTypeName)?.id;
        createModel.garbageCollectionBin = model.garbageCollectionBin;
        createModel.hasCabin = model.hasCabin;
        createModel.hasOperator = model.hasOperator;
        createModel.hasPlatform = model.hasPlatform;
        createModel.hasTowerElevationSystem = model.hasTowerElevationSystem;
        createModel.height = model.height;
        createModel.lessorCompanyId = model.lessorCompanyId;
        createModel.lightningCapacity = model.lightningCapacity;
        createModel.loadCapacity = model.loadCapacity;
        createModel.machineLocationCityId = allCities?.find((cty) => cty.name === model.machineLocationCityName)?.id;
        createModel.maxDepth = model.maxDepth;
        createModel.maxFeedingSize = model.maxFeedingSize;
        createModel.maxHeight = model.maxHeight;
        createModel.maxLoadCapacity = model.maxLoadCapacity;
        createModel.maxPower = model.maxPower;
        createModel.maxPressure = model.maxPressure;
        createModel.maxWidth = model.maxWidth;
        createModel.minPower = model.minPower;
        createModel.modelName = model.modelName;
        createModel.nominalCapacity = model.nominalCapacity;
        createModel.notes = model.notes;
        createModel.operatingArea = model.operatingArea;
        createModel.operatingCapacity = model.operatingCapacity;
        createModel.operatingHeight = model.operatingHeight;
        createModel.operatingLength = model.operatingLength;
        createModel.operatingWeight = model.operatingWeight;
        createModel.operationHour = model.operationHour;
        createModel.operationMileage = model.operationMileage;
        createModel.operationWidth = model.operationWidth;
        createModel.operatorCostIncluded = model.operatorCostIncluded;
        createModel.operatorExperienceYear = model.operatorExperienceYear;
        createModel.operatorInsuranceIncluded = model.operatorInsuranceIncluded;
        createModel.platformLength = model.platformLength;
        createModel.platformWidth = model.platformWidth;
        createModel.productionYear = model.productionYear;
        createModel.rentalTypes = model.rentalTypes?.map((item) => {
            return {
                currencyId: item.currency?.id ? item.currency.id : 1,
                price: item.price ? item.price : 0,
                rentalTypeId: item.rentalType?.id ? item.rentalType.id : 0,
            };
        });
        createModel.machineSalePrice = model.machineSalePrice;
        createModel.machineSalePriceCurrencyId = allCurrencies?.find((curr) => curr.name === model.machineSalePriceCurrencyName)?.id;
        createModel.isMachineForSale = model.isMachineForSale;
        let regions: number[] = [];
        model.serviceRegionsList?.forEach((regs) => {
            const sr: BasicModel | undefined = allCities?.find((cty) => cty.name === regs);
            if (sr && sr.id) regions.push(sr.id);
        });
        createModel.saleNotes = model.saleNotes;
        createModel.serviceRegions = regions;
        createModel.shipingIncluded = model.shipingIncluded;
        createModel.spraySpeed = model.spraySpeed;
        createModel.rentalAdTitle = model.rentalAdTitle;
        createModel.saleAdTitle = model.saleAdTitle;
        await doGetMachineSubcategory();
        let id: number | undefined = 0;
        allCategoryModels?.forEach((cat) => {
            const sub = cat.subCategories?.find((sub) => sub.name === model.subcategoryName);
            if (sub) id = sub.id;
        });
        createModel.subCategoryId = id;
        createModel.tankCapacity = model.tankCapacity;
        createModel.tankVolume = model.tankVolume;
        createModel.usageFields = model.usageFields;
        createModel.volume = model.volume;
        createModel.waterTankCapacity = model.waterTankCapacity;
        createModel.width = model.width;
        createModel.workingRadius = model.workingRadius;
        createModel.workingSpeed = model.workingSpeed;
        createModel.documents = model.documents?.map((doc) => {
            return {
                documentCategory: doc.documentCategory,
                documentId: doc.document?.id,
            };
        });

        try {
            let result: MachineDetailModel | undefined = undefined;
            for (let i = 0; i < createCount; i++) {
                result = await machineApi.machineCreatePost({ machineCreateModel: createModel }, postConfig());
            }
            if (result) {
                setErrorMessage({
                    type: "success",
                    message: t("common.success"),
                });
                return true;
            }
        } catch (err: any) {}
        return false;
    };

    const doCreateSaleFromRental = async (model: ExtendedMachineDetailModel, machineId?: number) => {
        let createModel: MachineCreateSaleModel = {};
        let attachments: number[] = [];
        model.attachmentsList?.forEach((item) => {
            const atc: BasicModel | undefined = allAttachments?.find((at) => at.name === item);
            if (atc && atc.id) attachments.push(atc.id);
        });
        createModel.machineId = machineId;
        createModel.bladeWidth = model.bladeWidth;
        createModel.bomLength = model.bomLength;
        createModel.brandId = allBrands?.find((brn) => brn.name === model.brandName)?.id;
        createModel.brandOther = model.otherBrandName;
        createModel.breakingInfo = model.breakingInfo;
        createModel.liftingHeight = model.liftingHeight;
        createModel.cameraResolutionId = allCameraResolutionTypes?.find((crt) => crt.name === model.cameraResolutionName)?.id;
        createModel.carryingCapacity = model.carryingCapacity;
        createModel.cleaningCapacity = model.cleaningCapacity;
        createModel.compressionWidth = model.compressionWidth;
        createModel.concretePumpingCapacity = model.concretePumpingCapacity;
        createModel.drivingEquipment = model.drivingEquipment;
        createModel.elevatorTypeId = allElevatorTypes?.find((elt) => elt.name === model.elevatorTypeName)?.id;
        createModel.engineBrand = model.engineBrand;
        createModel.enginePower = model.enginePower;
        createModel.flightDistance = model.flightDistance;
        createModel.flightTime = model.flightTime;
        createModel.floorCount = model.floorCount;
        createModel.freeAirOutput = model.freeAirOutput;
        createModel.frequency = model.frequency;
        createModel.fuelTypeId = allFuelTypes?.find((fty) => fty.name === model.fuelTypeName)?.id;
        createModel.garbageCollectionBin = model.garbageCollectionBin;
        createModel.hasCabin = model.hasCabin;
        createModel.hasPlatform = model.hasPlatform;
        createModel.hasTowerElevationSystem = model.hasTowerElevationSystem;
        createModel.height = model.height;
        createModel.lessorCompanyId = model.lessorCompanyId;
        createModel.lightningCapacity = model.lightningCapacity;
        createModel.loadCapacity = model.loadCapacity;
        createModel.machineLocationCityId = allCities?.find((cty) => cty.name === model.machineLocationCityName)?.id;
        createModel.maxDepth = model.maxDepth;
        createModel.maxFeedingSize = model.maxFeedingSize;
        createModel.maxHeight = model.maxHeight;
        createModel.maxLoadCapacity = model.maxLoadCapacity;
        createModel.maxPower = model.maxPower;
        createModel.maxPressure = model.maxPressure;
        createModel.maxWidth = model.maxWidth;
        createModel.minPower = model.minPower;
        createModel.modelName = model.modelName;
        createModel.nominalCapacity = model.nominalCapacity;
        createModel.operatingArea = model.operatingArea;
        createModel.operatingCapacity = model.operatingCapacity;
        createModel.operatingHeight = model.operatingHeight;
        createModel.operatingLength = model.operatingLength;
        createModel.operatingWeight = model.operatingWeight;
        createModel.operationHour = model.operationHour;
        createModel.operationMileage = model.operationMileage;
        createModel.operationWidth = model.operationWidth;
        createModel.platformLength = model.platformLength;
        createModel.platformWidth = model.platformWidth;
        createModel.productionYear = model.productionYear;
        createModel.machineSalePrice = model.machineSalePrice;
        createModel.saleAdTitle = model.saleAdTitle;
        createModel.machineSalePriceCurrencyId = allCurrencies?.find((curr) => curr.name === model.machineSalePriceCurrencyName)?.id;
        let regions: number[] = [];
        model.serviceRegionsList?.forEach((regs) => {
            const sr: BasicModel | undefined = allCities?.find((cty) => cty.name === regs);
            if (sr && sr.id) regions.push(sr.id);
        });
        createModel.saleNotes = model.saleNotes;
        createModel.spraySpeed = model.spraySpeed;
        await doGetMachineSubcategory();
        let id: number | undefined = 0;
        allCategoryModels?.forEach((cat) => {
            const sub = cat.subCategories?.find((sub) => sub.name === model.subcategoryName);
            if (sub) id = sub.id;
        });
        createModel.subCategoryId = id;
        createModel.tankCapacity = model.tankCapacity;
        createModel.tankVolume = model.tankVolume;
        createModel.usageFields = model.usageFields;
        createModel.volume = model.volume;
        createModel.waterTankCapacity = model.waterTankCapacity;
        createModel.width = model.width;
        createModel.workingRadius = model.workingRadius;
        createModel.workingSpeed = model.workingSpeed;
        createModel.documents = model.documents?.map((doc) => {
            return {
                documentCategory: doc.documentCategory,
                documentId: doc.document?.id,
            };
        });

        try {
            await machineApi.machineCreateSaleMachineFromRentalPost({ machineCreateSaleModel: createModel }, postConfig());
            return true;
        } catch (err: any) {
            return false;
        }
    }

    const doGetMachine = async (machineId: number, rental?: boolean) => {
        try {
            const mach = await machineApi.machineIdRentalGet({ id: machineId, rental: rental ? 1 : 0}, getConfig());
            let extended: ExtendedMachineDetailModel = { ...mach };
            extended.category = findCategory(mach.subCategoryId);
            extended.categoryName = extended.category?.name || undefined;
            extended.subcategoryName = mach.subCategory?.name || undefined;
            extended.brandName = mach.brand?.name || undefined;
            extended.cameraResolutionName = mach.cameraResolution?.name || undefined;
            extended.fuelTypeName = mach.fuelType?.name || undefined;
            extended.elevatorTypeName = mach.elevatorType?.name || undefined;
            extended.serviceRegionsList = mach.serviceRegions?.map((reg) => reg.name || "").filter((item) => item !== "");
            extended.attachmentsList = mach.attachments?.map((atc) => atc.name || "").filter((item) => item !== "");
            return extended;
        } catch {}
        return undefined;
    };

    const doGetSaleMachine = async (machineId: number) => {
        try {
            const mach = await machineApi.machineSaleIdGet({ id: machineId }, getConfig());
            let extended: ExtendedMachineDetailModel = { ...mach };
            extended.category = findCategory(mach.subCategoryId);
            extended.categoryName = extended.category?.name || undefined;
            extended.subcategoryName = mach.subCategory?.name || undefined;
            extended.brandName = mach.brand?.name || undefined;
            extended.cameraResolutionName = mach.cameraResolution?.name || undefined;
            extended.fuelTypeName = mach.fuelType?.name || undefined;
            extended.elevatorTypeName = mach.elevatorType?.name || undefined;
            extended.serviceRegionsList = mach.serviceRegions?.map((reg) => reg.name || "").filter((item) => item !== "");
            extended.attachmentsList = mach.attachments?.map((atc) => atc.name || "").filter((item) => item !== "");
            extended.machineLocationCityName = mach?.machineLocationCity?.name || "";
            extended.machineSalePriceCurrencyName =
                allCurrencies?.find((curr) => curr?.id === mach?.machineSalePriceCurrencyId)?.name ?? "";
            //extended.subCategory = extended.category?.subCategories?.find((sub)=>sub.id===extended.subCategoryId);
            return extended;
        } catch {}
        return undefined;
    };

    const doSearchMachine = async (model: MachineSearchModel) => {
        try {
            await doGetMachineSubcategory();
        } catch {}
        try {
            let result = (await machineApi.machineSearchPost({ machineSearchModel: model }, postConfig())) as SearchMachineModel;
            if (result) result.machines = [];
            result.data?.forEach((mach) => {
                let extended: ExtendedMachineDetailModel = { ...mach };
                extended.category = findCategory(mach.subCategoryId);
                //extended.subCategory = extended.category?.subCategories?.find((sub) => sub.id === extended.subCategoryId);
                result.machines?.push(extended);
            });
            return result;
        } catch {}
        return undefined;
    };

    const doGetMachineForRent = async (machineId: number, offerId: number) => {
        try {
            await doGetMachineSubcategory();
        } catch {}
        try {
            const result = await machineApi.machineMachineIdOfferFormOfferFormIdGet(
                { machineId: machineId, offerFormId: offerId },
                postConfig()
            );
            if (result) {
                let extended: ExtendedMachineDetailModel = { ...result };
                extended.category = findCategory(result.subCategoryId);
                extended.subCategory = extended.category?.subCategories?.find((sub) => sub.id === extended.subCategoryId);
                return extended;
            }
        } catch {}
        return undefined;
    };

    const doSearchRequests = async (model: RequestFormSearchModel) => {
        try {
            let result = await requestFormApi.requestFormSearchPost({ requestFormSearchModel: model }, postConfig());
            return result;
        } catch {}
        return undefined;
    };

    const doGetRequestDetail = async (reqId: number) => {
        try {
            let result = await requestFormApi.requestFormIdGet({ id: reqId }, getConfig());
            let extended: ModifiedRequestDetailModel = { ...result };
            extended.fuelTypeName = result.fuelType?.name || undefined;
            return extended;
        } catch {}
        return undefined;
    };

    const doUpdateRequest = async (model: ModifiedRequestDetailModel) => {
        let documents: number[] = [];
        model.documents?.forEach((item) => {
            if (item && item.id) documents.push(item.id);
        });

        let attachments: number[] = [];
        model.attachments?.forEach((item) => {
            if (item && item.id) attachments.push(item.id);
        });

        let newModel: RequestFormEditModel = {
            additionalServices: model.additionalServices,
            subCategoryOther: model.subCategoryOther,
            attachments: attachments,
            attachmentsOther: model.attachmentsOther,
            dailyWorkingTime: model.dailyWorkingTime ? model.dailyWorkingTime : 0,
            description: model.description,
            districtId: model.district?.id ? model.district.id : 0,
            documents: documents,
            id: model.id ? model.id : 0,
            machineCount: model.machineCount ? model.machineCount : 1,
            machineFeatures: {
                ...model.machineFeatures,
                fuelTypeId: allFuelTypes?.find((x) => x.name === model.fuelTypeName)?.id,
            },
            requestDateFrom: model.fromDateRaw?.toDate() || new Date(),
            requestDateTo: model.toDateRaw?.toDate() || new Date(),
            subCategoryId: model.subCategory?.id ? model.subCategory.id : 0,
            machineId: model.machineId,
        };

        try {
            let result = await requestFormApi.requestFormUpdatePost({ requestFormEditModel: newModel }, postConfig());
            if (result) {
                setErrorMessage({
                    type: "success",
                    message: t("common.success"),
                });
            }
            return result;
        } catch {}
        return undefined;
    };

    const doDeleteMachine = async (id: number) => {
        try {
            const result = await machineApi.machineIdDelete({ id: id }, getConfig());
            if (result) {
                setErrorMessage({
                    type: "success",
                    message: t("common.success"),
                });
                return true;
            }
        } catch {}
        return false;
    };
    const doCreateRentalFromSale = async (model: ExtendedMachineDetailModel) => {
        let createModel: MachineEditModel = {};
        let attachments: number[] = [];
        model.attachmentsList?.forEach((item) => {
            const atc: BasicModel | undefined = allAttachments?.find((at) => at.name === item);
            if (atc && atc.id) attachments.push(atc.id);
        });
        let brandId = allBrands?.find((brn) => brn.name === model.brandName)?.id;
        createModel.attachments = attachments;
        createModel.liftingHeight = model.liftingHeight;
        createModel.attachmentOther = model.otherAttachmentsName;
        createModel.bladeWidth = model.bladeWidth;
        createModel.bomLength = model.bomLength;
        createModel.brandId = brandId;
        createModel.brandOther = brandId === 0 ? model.otherBrandName : undefined;
        createModel.breakingInfo = model.breakingInfo;
        createModel.cameraResolutionId = allCameraResolutionTypes?.find((crt) => crt.name === model.cameraResolutionName)?.id;
        createModel.carryingCapacity = model.carryingCapacity;
        createModel.cleaningCapacity = model.cleaningCapacity;
        createModel.compressionWidth = model.compressionWidth;
        createModel.concretePumpingCapacity = model.concretePumpingCapacity;
        createModel.drivingEquipment = model.drivingEquipment;
        createModel.elevatorTypeId = allElevatorTypes?.find((elt) => elt.name === model.elevatorTypeName)?.id;
        createModel.engineBrand = model.engineBrand;
        createModel.enginePower = model.enginePower;
        createModel.flightDistance = model.flightDistance;
        createModel.flightTime = model.flightTime;
        createModel.floorCount = model.floorCount;
        createModel.freeAirOutput = model.freeAirOutput;
        createModel.frequency = model.frequency;
        createModel.fuelIncluded = model.fuelIncluded;
        createModel.fuelTypeId = allFuelTypes?.find((fty) => fty.name === model.fuelTypeName)?.id;
        createModel.garbageCollectionBin = model.garbageCollectionBin;
        createModel.hasCabin = model.hasCabin;
        createModel.hasOperator = model.hasOperator;
        createModel.hasPlatform = model.hasPlatform;
        createModel.hasTowerElevationSystem = model.hasTowerElevationSystem;
        createModel.height = model.height;
        createModel.id = model.id;
        createModel.isMachineForSale = model.isMachineForSale;
        createModel.lessorCompanyId = model.lessorCompanyId;
        createModel.lightningCapacity = model.lightningCapacity;
        createModel.loadCapacity = model.loadCapacity;
        createModel.machineLocationCityId = allCities?.find((cty) => cty.name === model.machineLocationCityName)?.id;
        createModel.machineSalePrice = model.machineSalePrice;
        createModel.machineSalePriceCurrencyId = allCurrencies?.find((curr) => curr.name === model.machineSalePriceCurrencyName)?.id;
        createModel.maxDepth = model.maxDepth;
        createModel.maxFeedingSize = model.maxFeedingSize;
        createModel.maxHeight = model.maxHeight;
        createModel.maxLoadCapacity = model.maxLoadCapacity;
        createModel.maxPower = model.maxPower;
        createModel.maxPressure = model.maxPressure;
        createModel.maxWidth = model.maxWidth;
        createModel.minPower = model.minPower;
        createModel.modelName = model.modelName;
        createModel.nominalCapacity = model.nominalCapacity;
        createModel.notes = model.notes;
        createModel.operatingArea = model.operatingArea;
        createModel.operatingCapacity = model.operatingCapacity;
        createModel.operatingHeight = model.operatingHeight;
        createModel.operatingLength = model.operatingLength;
        createModel.operatingWeight = model.operatingWeight;
        createModel.operationHour = model.operationHour;
        createModel.operationMileage = model.operationMileage;
        createModel.operationWidth = model.operationWidth;
        createModel.operatorCostIncluded = model.operatorCostIncluded;
        createModel.operatorExperienceYear = model.operatorExperienceYear;
        createModel.operatorInsuranceIncluded = model.operatorInsuranceIncluded;
        createModel.platformLength = model.platformLength;
        createModel.platformWidth = model.platformWidth;
        createModel.productionYear = model.productionYear;
        createModel.rentalTypes = model.rentalTypes?.map((item) => {
            return {
                currencyId: item.currency?.id ? item.currency.id : 1,
                price: item.price ? item.price : 0,
                rentalTypeId: item.rentalType?.id ? item.rentalType.id : 0,
            };
        });
        let regions: number[] = [];
        model.serviceRegionsList?.forEach((regs) => {
            const sr: BasicModel | undefined = allCities?.find((cty) => cty.name?.toLowerCase() === regs.toLowerCase());
            if (sr && sr.id) regions.push(sr.id);
        });
        createModel.saleNotes = model.saleNotes;
        createModel.serviceRegions = regions;
        createModel.shipingIncluded = model.shipingIncluded;
        createModel.spraySpeed = model.spraySpeed;
        createModel.subCategoryId = model.subCategoryId;
        createModel.tankCapacity = model.tankCapacity;
        createModel.tankVolume = model.tankVolume;
        createModel.usageFields = model.usageFields;
        createModel.volume = model.volume;
        createModel.waterTankCapacity = model.waterTankCapacity;
        createModel.width = model.width;
        createModel.workingRadius = model.workingRadius;
        createModel.workingSpeed = model.workingSpeed;
        createModel.rentalAdTitle = model.rentalAdTitle;
        createModel.documents = model.documents?.map((doc) => {
            return {
                documentCategory: doc.documentCategory,
                documentId: doc.document?.id,
            };
        });
        try {
            await machineApi.machineCreateRentalFromSalePost({ machineEditModel: createModel }, postConfig());
            setErrorMessage({
                type: "success",
                message: t("common.success"),
            });
            return true;
        } catch {}
        return false;
    }
    const doUpdateMachine = async (model: ExtendedMachineDetailModel) => {
        let createModel: MachineEditModel = {};
        let attachments: number[] = [];
        model.attachmentsList?.forEach((item) => {
            const atc: BasicModel | undefined = allAttachments?.find((at) => at.name === item);
            if (atc && atc.id) attachments.push(atc.id);
        });
        let brandId = allBrands?.find((brn) => brn.name === model.brandName)?.id;
        createModel.attachments = attachments;
        createModel.attachmentOther = model.otherAttachmentsName;
        createModel.bladeWidth = model.bladeWidth;
        createModel.bomLength = model.bomLength;
        createModel.brandId = brandId;
        createModel.brandOther = brandId === 0 ? model.otherBrandName : undefined;
        createModel.breakingInfo = model.breakingInfo;
        createModel.cameraResolutionId = allCameraResolutionTypes?.find((crt) => crt.name === model.cameraResolutionName)?.id;
        createModel.carryingCapacity = model.carryingCapacity;
        createModel.cleaningCapacity = model.cleaningCapacity;
        createModel.compressionWidth = model.compressionWidth;
        createModel.concretePumpingCapacity = model.concretePumpingCapacity;
        createModel.drivingEquipment = model.drivingEquipment;
        createModel.elevatorTypeId = allElevatorTypes?.find((elt) => elt.name === model.elevatorTypeName)?.id;
        createModel.engineBrand = model.engineBrand;
        createModel.enginePower = model.enginePower;
        createModel.flightDistance = model.flightDistance;
        createModel.flightTime = model.flightTime;
        createModel.floorCount = model.floorCount;
        createModel.liftingHeight = model.liftingHeight;
        createModel.freeAirOutput = model.freeAirOutput;
        createModel.frequency = model.frequency;
        createModel.fuelIncluded = model.fuelIncluded;
        createModel.fuelTypeId = allFuelTypes?.find((fty) => fty.name === model.fuelTypeName)?.id;
        createModel.garbageCollectionBin = model.garbageCollectionBin;
        createModel.hasCabin = model.hasCabin;
        createModel.hasOperator = model.hasOperator;
        createModel.hasPlatform = model.hasPlatform;
        createModel.hasTowerElevationSystem = model.hasTowerElevationSystem;
        createModel.height = model.height;
        createModel.id = model.id;
        createModel.isMachineForSale = model.isMachineForSale;
        createModel.lessorCompanyId = model.lessorCompanyId;
        createModel.lightningCapacity = model.lightningCapacity;
        createModel.loadCapacity = model.loadCapacity;
        createModel.machineLocationCityId = allCities?.find((cty) => cty.name === model.machineLocationCityName)?.id;
        createModel.machineSalePrice = model.machineSalePrice;
        createModel.machineSalePriceCurrencyId = allCurrencies?.find((curr) => curr.name === model.machineSalePriceCurrencyName)?.id;
        createModel.maxDepth = model.maxDepth;
        createModel.maxFeedingSize = model.maxFeedingSize;
        createModel.maxHeight = model.maxHeight;
        createModel.maxLoadCapacity = model.maxLoadCapacity;
        createModel.maxPower = model.maxPower;
        createModel.maxPressure = model.maxPressure;
        createModel.maxWidth = model.maxWidth;
        createModel.minPower = model.minPower;
        createModel.modelName = model.modelName;
        createModel.nominalCapacity = model.nominalCapacity;
        createModel.notes = model.notes;
        createModel.operatingArea = model.operatingArea;
        createModel.operatingCapacity = model.operatingCapacity;
        createModel.operatingHeight = model.operatingHeight;
        createModel.operatingLength = model.operatingLength;
        createModel.operatingWeight = model.operatingWeight;
        createModel.operationHour = model.operationHour;
        createModel.operationMileage = model.operationMileage;
        createModel.operationWidth = model.operationWidth;
        createModel.operatorCostIncluded = model.operatorCostIncluded;
        createModel.operatorExperienceYear = model.operatorExperienceYear;
        createModel.operatorInsuranceIncluded = model.operatorInsuranceIncluded;
        createModel.platformLength = model.platformLength;
        createModel.platformWidth = model.platformWidth;
        createModel.productionYear = model.productionYear;
        createModel.rentalAdTitle = model.rentalAdTitle;
        createModel.rentalTypes = model.rentalTypes?.map((item) => {
            return {
                currencyId: item.currency?.id ? item.currency.id : 1,
                price: item.price ? item.price : 0,
                rentalTypeId: item.rentalType?.id ? item.rentalType.id : 0,
            };
        });
        let regions: number[] = [];
        model.serviceRegionsList?.forEach((regs) => {
            const sr: BasicModel | undefined = allCities?.find((cty) => cty.name?.toLowerCase() === regs.toLowerCase());
            if (sr && sr.id) regions.push(sr.id);
        });
        createModel.saleNotes = model.saleNotes;
        createModel.serviceRegions = regions;
        createModel.shipingIncluded = model.shipingIncluded;
        createModel.spraySpeed = model.spraySpeed;
        let id: number | undefined = model.subCategoryId;
        allCategoryModels?.forEach(cat => cat.subCategories?.some(sub => (sub.name === model.subcategoryName) && (id = sub.id)));
        createModel.subCategoryId = id;
        createModel.tankCapacity = model.tankCapacity;
        createModel.tankVolume = model.tankVolume;
        createModel.usageFields = model.usageFields;
        createModel.volume = model.volume;
        createModel.waterTankCapacity = model.waterTankCapacity;
        createModel.width = model.width;
        createModel.workingRadius = model.workingRadius;
        createModel.workingSpeed = model.workingSpeed;
        createModel.documents = model.documents?.map((doc) => {
            return {
                documentCategory: doc.documentCategory,
                documentId: doc.document?.id,
            };
        });
        try {
            await machineApi.machineUpdatePost({ machineEditModel: createModel }, postConfig());
            setErrorMessage({
                type: "success",
                message: t("common.success"),
            });
            return true;
        } catch {}
        return false;
    };

    const doUpdateSaleMachine = async (model: ExtendedMachineDetailModel, macId: number) => {
        let createModel: MachineCreateSaleModel  = {};
        let brandId = allBrands?.find((brn) => brn.name === model.brandName)?.id;
        createModel.bladeWidth = model.bladeWidth;
        createModel.bomLength = model.bomLength;
        createModel.brandId = brandId;
        createModel.brandOther = brandId === 0 ? model.otherBrandName : undefined;
        createModel.breakingInfo = model.breakingInfo;
        createModel.liftingHeight = model.liftingHeight;
        createModel.cameraResolutionId = allCameraResolutionTypes?.find((crt) => crt.name === model.cameraResolutionName)?.id;
        createModel.carryingCapacity = model.carryingCapacity;
        createModel.cleaningCapacity = model.cleaningCapacity;
        createModel.compressionWidth = model.compressionWidth;
        createModel.concretePumpingCapacity = model.concretePumpingCapacity;
        createModel.drivingEquipment = model.drivingEquipment;
        createModel.elevatorTypeId = allElevatorTypes?.find((elt) => elt.name === model.elevatorTypeName)?.id;
        createModel.engineBrand = model.engineBrand;
        createModel.enginePower = model.enginePower;
        createModel.flightDistance = model.flightDistance;
        createModel.flightTime = model.flightTime;
        createModel.floorCount = model.floorCount;
        createModel.freeAirOutput = model.freeAirOutput;
        createModel.frequency = model.frequency;
        createModel.fuelTypeId = allFuelTypes?.find((fty) => fty.name === model.fuelTypeName)?.id;
        createModel.garbageCollectionBin = model.garbageCollectionBin;
        createModel.hasCabin = model.hasCabin;
        createModel.hasPlatform = model.hasPlatform;
        createModel.hasTowerElevationSystem = model.hasTowerElevationSystem;
        createModel.height = model.height;
        createModel.lessorCompanyId = model.lessorCompanyId;
        createModel.lightningCapacity = model.lightningCapacity;
        createModel.loadCapacity = model.loadCapacity;
        createModel.machineLocationCityId = allCities?.find((cty) => cty.name === model.machineLocationCityName)?.id;
        createModel.machineSalePrice = model.machineSalePrice;
        createModel.machineSalePriceCurrencyId = allCurrencies?.find((curr) => curr.name === model.machineSalePriceCurrencyName)?.id;
        createModel.maxDepth = model.maxDepth;
        createModel.maxFeedingSize = model.maxFeedingSize;
        createModel.maxHeight = model.maxHeight;
        createModel.maxLoadCapacity = model.maxLoadCapacity;
        createModel.maxPower = model.maxPower;
        createModel.maxPressure = model.maxPressure;
        createModel.maxWidth = model.maxWidth;
        createModel.minPower = model.minPower;
        createModel.modelName = model.modelName;
        createModel.nominalCapacity = model.nominalCapacity;
        createModel.operatingArea = model.operatingArea;
        createModel.operatingCapacity = model.operatingCapacity;
        createModel.operatingHeight = model.operatingHeight;
        createModel.operatingLength = model.operatingLength;
        createModel.operatingWeight = model.operatingWeight;
        createModel.operationHour = model.operationHour;
        createModel.operationMileage = model.operationMileage;
        createModel.operationWidth = model.operationWidth;
        createModel.platformLength = model.platformLength;
        createModel.platformWidth = model.platformWidth;
        createModel.productionYear = model.productionYear;
        createModel.saleNotes = model.saleNotes;
        createModel.spraySpeed = model.spraySpeed;
        let id: number | undefined = model.subCategoryId;
        allCategoryModels?.forEach(cat => cat.subCategories?.some(sub => (sub.name === model.subcategoryName) && (id = sub.id)));
        createModel.subCategoryId = id;
        createModel.tankCapacity = model.tankCapacity;
        createModel.tankVolume = model.tankVolume;
        createModel.usageFields = model.usageFields;
        createModel.volume = model.volume;
        createModel.waterTankCapacity = model.waterTankCapacity;
        createModel.width = model.width;
        createModel.workingRadius = model.workingRadius;
        createModel.workingSpeed = model.workingSpeed;
        createModel.machineId = macId;
        createModel.saleAdTitle = model.saleAdTitle;
        createModel.documents = model.documents?.map((doc) => {
            return {
                documentCategory: doc.documentCategory,
                documentId: doc.document?.id,
            };
        });
        try {
            await machineApi.machineUpdateSaleMachinePost({ machineCreateSaleModel: createModel }, postConfig());
            setErrorMessage({
                type: "success",
                message: t("common.success"),
            });
            return true;
        } catch {}
        return false;
    };

    const doCreateNewRequest = async (modifiedModel: ModifiedRequestDetailModel | undefined) => {
        if (!modifiedModel || !modifiedModel.subCategory || !modifiedModel.district) {
            handleParameterErrors(["talep formu"]);
            return false;
        }

        let model: RequestFormCreateModel = {
            subCategoryId: modifiedModel.subCategory.id || 0,
            subCategoryOther: modifiedModel.subCategoryOther || "",
            districtId: modifiedModel.district.id || 0,
            requestDateFrom: modifiedModel.fromDateRaw?.toDate() || new Date(),
            requestDateTo: modifiedModel.toDateRaw?.toDate() || new Date(),
            machineCount: modifiedModel.machineCount || 1,
            dailyWorkingTime: modifiedModel.dailyWorkingTime || 0,
            description: modifiedModel.description,
            additionalServices: modifiedModel.additionalServices,
            attachments: modifiedModel.attachments?.filter((atc) => atc && atc.id).map((atc) => atc.id || 0),
            attachmentsOther: modifiedModel.attachmentsOther,
            documents: modifiedModel.documents?.filter((doc) => doc && doc.id).map((doc) => doc.id || 0),
            machineFeatures: {
                ...modifiedModel.machineFeatures,
                fuelTypeId: modifiedModel.fuelType?.id,
            },
            machineId: modifiedModel.machineId,
        };

        try {
            const result = await requestFormApi.requestFormCreatePost({ requestFormCreateModel: model }, postConfig());
            if (result) return true;
        } catch {}
        return false;
    };

    const doLogout = async () => {
        try {
            if (role === undefined) {
                return false;
            }
            role !== "lessor"
                ? await renterCompanyApi.renterCompanySignOutGet(getConfig())
                : await lessorCompanyApi.lessorCompanySignOutGet(getConfig());
            setUser(undefined);
            setLessorPurpose(undefined);
            setRole(undefined);
            return true;
        } catch (err: any) {
            setUser(undefined);
            setLessorPurpose(undefined);
            setRole(undefined);
            return false;
        }
    };

    const doSwitch = async () => {
        try {
            if (role === undefined) {
                return false;
            }
            const result =
                role !== "lessor"
                    ? await renterCompanyApi.renterCompanySwitchToLessorDashboardGet(getConfig())
                    : await lessorCompanyApi.lessorCompanySwitchToRenterDashboardGet(getConfig());

            setUser(result);
            setRole(result.role === "Lessor" ? "lessor" : "customer");
            return true;
        } catch (err: any) {
            setUser(undefined);
            setLessorPurpose(undefined);
            setRole(undefined);
            return false;
        }
    };

    const doRejectRequest = async (id: number, note: string | undefined) => {
        try {
            await requestFormApi.requestFormRejectRequestFormIdPost(
                { requestFormId: id, requestFormRejectModel: { note: note } },
                postConfig()
            );
            return true;
        } catch {
            return false;
        }
    };

    const doApproveRequest = async (id: number, note: string | undefined) => {
        try {
            await requestFormApi.requestFormApproveRequestFormIdPost(
                { requestFormId: id, requestFormRejectModel: { note: note } },
                postConfig()
            );
            return true;
        } catch {
            return false;
        }
    };

    const doSearchOffers = async (model: OfferFormSearchModel) => {
        try {
            let result = await offerFormApi.offerFormSearchPost({ offerFormSearchModel: model }, postConfig());
            return result;
        } catch {}
        return undefined;
    };

    const doGetOfferDetail = async (offerId: number) => {
        try {
            let result = await offerFormApi.offerFormIdGet({ id: offerId }, postConfig());
            return result;
        } catch {}
        return undefined;
    };

    const doGetSuitableMachinesToOffer = async (offerId: number) => {
        try {
            let result = await machineApi.machineGetSuitableMachinesForOfferOfferIdGet({ offerId: offerId }, getConfig());
            return result;
        } catch {}
        return undefined;
    };

    const doGetCompanyDetailForRequest = async (offerId: number, reqId: number) => {
        try {
            await doGetRequestDetail(reqId);
        } catch {}
        try {
            let result = await requestFormApi.requestFormCompanyDetailRequestFormIdOfferFormOfferFormIdGet(
                { offerFormId: offerId, requestFormId: reqId },
                getConfig()
            );
            return result;
        } catch {}
        return undefined;
    };

    const doGetAuthorisedLessorDetailForRequest = async (offerId: number, reqId: number) => {
        try {
            await doGetRequestDetail(reqId);
        } catch {}
        try {
            let result = await requestFormApi.requestFormCompanyAuthorisedLessorContactDetailRequestFormIdOfferFormOfferFormIdGet(
                { offerFormId: offerId, requestFormId: reqId },
                getConfig()
            );
            return result;
        } catch {}
        return undefined;
    };

    const doGetPaymentTypes = async () => {
        if (allPaymentTypes) return allPaymentTypes;
        try {
            const recaptcha = await generateRecaptcha("guest");
            if (!recaptcha) return undefined;
            allPaymentTypes = await paymentTypeApi.paymentTypeGet(getConfig(false, recaptcha));
            return allPaymentTypes;
        } catch {}
    };

    const doGetMaturityOptions = async () => {
        if (allMaturityOptions) return allMaturityOptions;
        try {
            const recaptcha = await generateRecaptcha("guest");
            if (!recaptcha) return undefined;
            allMaturityOptions = await maturityOptionApi.maturityOptionGet(getConfig(false, recaptcha));
            return allMaturityOptions;
        } catch {}
    };

    const doPostOffer = async (model: OfferFormEditModel) => {
        try {
            const result = await offerFormApi.offerFormOfferPost({ offerFormEditModel: model }, postConfig());
            if (result) return true;
        } catch {}
        return false;
    };

    const doUpdateOffer = async (model: OfferFormEditModel) => {
        try {
            const result = await offerFormApi.offerFormUpdatePost({ offerFormEditModel: model }, postConfig());
            if (result) return true;
        } catch {}
        return false;
    };

    const doAcceptOfferForm = async (bidId: number, offerId: number) => {
        try {
            const result = await offerFormApi.offerFormAcceptOfferIdBidIdGet({ bidId: bidId, offerId: offerId }, postConfig());
            return result;
        } catch {}
        return undefined;
    };

    const doDeclineOfferForm = async (offerId: number, comment: string) => {
        try {
            const result = await offerFormApi.offerFormDeclinePost(
                { offerFormCommentModel: { offerFormId: offerId, comment: comment } },
                postConfig()
            );
            return result;
        } catch {}
        return false;
    };

    const doGetRelatedOffersOfMachine = async (machineId: number) => {
        try {
            const result = await machineApi.machineIdOfferFormsGet({ id: machineId }, getConfig());
            if (result) return result;
        } catch {}
        return undefined;
    };

    const doGetLessorCompanyDetails = async () => {
        try {
            const result = await lessorCompanyApi.lessorCompanyInfoGet(getConfig());
            return result;
        } catch {}
        return undefined;
    };

    const doGetLessorCompanyUsers = async (companyId: number) => {
        try {
            const result = await lessorCompanyApi.lessorCompanyListUsersCompanyIdGet({ companyId }, getConfig());
            return result;
        } catch {}
        return undefined;
    };

    const doGetLessorCompanyProfile = async () => {
        try {
            const result = await lessorCompanyApi.lessorCompanyProfileGet(getConfig());
            return result;
        } catch {}
        return undefined;
    };
    const doGetCategoryWithChildren = async () => {
        try {
            const result = await categoryApi.categoryWithChildrenGet(getConfig());
            return result;
        } catch {}
        return undefined;
    };
    const doGetMachines = async (model: MachineDetailModelPagedDataModel) => {
        try {
            const result = await machineApi.machineMachineSearchPost({ machineListSearchModel: model }, postConfig());
            return result;
        } catch {}
        return undefined;
    };

    const doGetRenterCompanyProfile = async (renterId: any) => {
        try {
            const result = await renterCompanyApi.renterCompanyProfileUserIdGet({ userId: renterId }, getConfig());
            return result;
        } catch {}
        return undefined;
    };

    const doGetRenterCompanyInfo = async (renterId: any) => {
        try {
            const result = await renterCompanyApi.renterCompanyInfoUserIdGet({ userId: renterId }, getConfig());
            return result;
        } catch {}
        return undefined;
    };

    const doSaveLessorCompanyProfile = async (model: LessorCompanyProfileCreateModel) => {
        try {
            const result = await lessorCompanyApi.lessorCompanyProfilePost({ lessorCompanyProfileCreateModel: model }, postConfig());
            return result;
        } catch {}
        return undefined;
    };

    const doSaveRenterCompanyProfile = async (renterId: any, model: RenterCompanyProfileCreateModel) => {
        try {
            const result = await renterCompanyApi.renterCompanyProfileUserIdPost(
                { userId: renterId, renterCompanyProfileCreateModel: model },
                postConfig()
            );
            return result;
        } catch {}
        return undefined;
    };

    const doUpdateRenterCompanyInfo = async (renterId: any, model: RenterCompanyPaymentUpdateModel) => {
        try {
            const result = await renterCompanyApi.renterCompanyPaymentInfoUserIdPost(
                { userId: renterId, renterCompanyPaymentUpdateModel: model },
                postConfig()
            );
            return result;
        } catch {}
        return undefined;
    };

    const doSaveLessorCompanyInfo = async (model: LessorCompanyInfoUpdateModel) => {
        try {
            const result = await lessorCompanyApi.lessorCompanyInfoPost({ lessorCompanyInfoUpdateModel: model }, postConfig());
            return result;
        } catch {}
        return undefined;
    };

    const doUpdateLessorPaymentInfo = async (model: LessorCompanyPaymentInfoViewModel) => {
        try {
            const result = await lessorCompanyApi.lessorCompanyUpdatePaymentInfoPost(
                { lessorCompanyPaymentInfoViewModel: model },
                postConfig()
            );
            return result;
        } catch {}
        return undefined;
    };

    const doUpdateRenterPaymentInfo = async (renterId: any, model: RenterCompanyPaymentUpdateModel) => {
        try {
            const result = await renterCompanyApi.renterCompanyPaymentInfoUserIdPost(
                { userId: renterId, renterCompanyPaymentUpdateModel: model },
                postConfig()
            );
            return result;
        } catch {}
        return undefined;
    };

    const doGetLessorCompanyDetail = async (lessorId: number) => {
        try {
            let result = await lessorCompanyApi.lessorCompanyIdGet({ id: lessorId }, getConfig());
            return result;
        } catch {}
        return undefined;
    };

    const doGetRenterCompanyDetail = async (renterId: any) => {
        try {
            let result = await renterCompanyApi.renterCompanyPaymentInfoUserIdGet({ userId: renterId }, getConfig());
            return result;
        } catch {}
        return undefined;
    };

    const doBlockLessorCompany = async (id: number) => {
        try {
            await lessorCompanyApi.lessorCompanyChangeBlockStatusUserIdGet({ userId: id }, getConfig());
            return true;
        } catch {}
        return false;
    };

    const doDeleteLessorUser = async (id: number) => {
        try {
            await lessorCompanyApi.lessorCompanyDeleteUserUserIdGet({ userId: id }, getConfig());
            return true;
        } catch {}
        return false;
    };

    const doBlockRenterCompany = async (id: number) => {
        try {
            await renterCompanyApi.renterCompanyChangeBlockStatusUserIdGet({ userId: id }, getConfig());
            return true;
        } catch {}
        return false;
    };

    const doGetLessorBlocked = async (id: number) => {
        try {
            let result = await lessorCompanyApi.lessorCompanyGetBlockStatusUserIdGet({ userId: id }, getConfig());
            return result;
        } catch {}
        return undefined;
    };

    const doGetRenterBlocked = async (id: number) => {
        try {
            let result = await renterCompanyApi.renterCompanyGetBlockStatusUserIdGet({ userId: id }, getConfig());
            return result;
        } catch {}
        return undefined;
    };

    const doGetBillingTimeOptions = async () => {
        try {
            const recaptcha = await generateRecaptcha("guest");
            if (!recaptcha) return undefined;
            let result = await billingTimeOptionApi.billingTimeOptionGet(getConfig(false, recaptcha));
            return result;
        } catch {}
    };

    const doGetPaymentPeriod = async () => {
        try {
            const recaptcha = await generateRecaptcha("guest");
            if (!recaptcha) return undefined;
            let result = await paymentPeriodApi.paymentPeriodGet(getConfig(false, recaptcha));
            return result;
        } catch {}
    };

    const doCreateLessorUser = async (model: LessorUserCreateViewModel) => {
        try {
            let result = await lessorCompanyApi.lessorCompanyCreateUserPost({ lessorUserCreateViewModel: model }, postConfig());
            return result;
        } catch {}
        return undefined;
    };

    const doGetNotificationCount = async (receiverId: any) => {
        try {
            const result = await notificationApi.notificationCountReceiverIdGet({ receiverId: receiverId }, getConfig());
            return result;
        } catch (err: any) {
            return undefined;
        }
    };

    const doGetNotifications = async (receiverId: any) => {
        try {
            const result = await notificationApi.notificationReceiverIdGet({ receiverId: receiverId }, getConfig());
            return result;
        } catch (err: any) {
            return undefined;
        }
    };

    const doGetAllNotifications = async (receiverId: any) => {
        try {
            const result = await notificationApi.notificationAllReceiverIdGet({ receiverId: receiverId }, getConfig());
            return result;
        } catch {}
        return undefined;
    };

    const doRenterRegisterWithRequest = async (model: RenterUserCreateModel) => {
        try {
            const recaptcha = await generateRecaptcha("renterrequestform");
            if (!recaptcha) return undefined;
            const result = await renterCompanyApi.renterCompanyRegisterWithRequestPost(
                { renterUserCreateModel: model },
                postConfig(false, recaptcha)
            );
            return result.data;
        } catch (err: any) {
            return undefined;
        }
    };

    const doCreateNewRequestWithRegister = async (model: RequestFormCreateWithRegisterModel) => {
        try {
            const recaptcha = await generateRecaptcha("renterrequestform");
            if (!recaptcha) return undefined;
            const result = await requestFormApi.requestFormCreateWithRegisterPost(
                { requestFormCreateWithRegisterModel: model },
                postConfig(undefined, recaptcha)
            );
            if (result) return true;
        } catch {}
        return false;
    };

    const doUpdateLessorUserAgreements = async (model: LessorCompanyAgreementsViewModel) => {
        try {
            const result = await lessorCompanyApi.lessorCompanyUpdateAgreementsVersionsPost(
                { lessorCompanyAgreementsViewModel: model },
                postConfig()
            );
            return result;
        } catch {}
        return false;
    };

    const doUpdateRenterUserAgreements = async (model: RenterCompanyAgreementsViewModel) => {
        try {
            const result = await renterCompanyApi.renterCompanyUpdateAgreementsVersionsPost(
                { renterCompanyAgreementsViewModel: model },
                postConfig()
            );
            return result;
        } catch {}
        return false;
    };

    const doUnApproveMachine = async (model: MachineApprovalModel) => {
        try {
            const result = await machineApi.machineUnapprovePost({ machineApprovalModel: model }, postConfig());
            return result;
        } catch (err: any) {
            await handleSecureCatchErrors(err);
            return undefined;
        }
    };


    const doUnApproveSaleMachine = async (model: MachineApprovalModel) => {
        try {
            const result = await machineApi.machineSaleUnapprovePost({ machineApprovalModel: model }, postConfig());
            return result;
        } catch (err: any) {
            await handleSecureCatchErrors(err);
            return undefined;
        }
    };

    const doGetSaleMachines = async (model: MachineSaleListModelPagedDataModel) => {
        try {
            const result = await machineApi.machineSaleMachineSearchPost({ machineSaleListSearchModel: model }, postConfig());
            return result;
        } catch (err: any) {
            await handleSecureCatchErrors(err);
            return undefined;
        }
    };

    const doRemoveSaleMachine = async (machineId: number) => {
        if (role === 'lessor')
        {
            try {
                const result = await machineApi.machineRemoveSaleMachineMachineIdPost({ machineId: machineId }, postConfig());
                return result;
            } catch (err: any) {
                await handleSecureCatchErrors(err);
                return undefined;
            }
        }
    }

    const doGetMachineCommissionsForSale = async () => {
        setLoading(true);
        try {
            const result = await machineCommissionApi.machineCommissionsForSaleGet(getConfig());
            setLoading(false);
            return result;
        } catch (err: any) {
            await handleSecureCatchErrors(err);
            return undefined;
        }
    };

    const doGetMachineCommissionsForFee = async () => {
        setLoading(true);
        try {
            const result = await machineCommissionApi.machineCommissionsForFeeGet(getConfig());
            setLoading(false);
            return result;
        } catch (err: any) {
            await handleSecureCatchErrors(err);
            return undefined;
        }
    };


    const doActivateWithUpdate = async (model: ActivationWithUpdateModel) => {
        try {
            const result = await renterCompanyApi.renterCompanyActivateWithUpdatePost({ activationWithUpdateModel: model }, postConfig());
            return result;
        } catch (err: any) {
            await handleSecureCatchErrors(err);
            return undefined;
        }
    };

    const doSetPassword = async (model: SetPasswordModel) => {
        try {
            const result = await renterCompanyApi.renterCompanySetPasswordPost({ setPasswordModel: model }, postConfig());
            setUser(result);
            setRole((result.role = "customer"));
            return result;
        } catch (err: any) {
            await handleSecureCatchErrors(err);
            return undefined;
        }
    };

    const doGetExchangeRates = async () => {
        setLoading(true);
        try {
            const result = await currenciesApi.currencyExchangeRatesGet(getConfig());
            setLoading(false);
            return result;
        } catch (err: any) {
            await handleSecureCatchErrors(err);
            return undefined;
        }
    };

    const doGetLessorByMachineId = async (machineId: number) => {
        setLoading(true);
        try {
            const recaptcha = await generateRecaptcha("guest");
            if (!recaptcha) return undefined;
            const result = await lessorCompanyApi.lessorCompanyMachineOwnerByMachineIdMachineIdGet({ machineId: machineId }, getConfig());
            setLoading(false);
            return result;
        } catch (err: any) {
            await handleSecureCatchErrors(err);
            return undefined;
        }
    };

    const doAuthorizeLessorsForSale = async (authorizedLessors: number[], unauthorizedLessors: number[]) => {
        setLoading(true);
        try {
            const result = await lessorCompanyApi.lessorCompanyAuthoriseLessorPost({ lessorCompanyAuthoriseLessorViewModel: {authorisedLessors: authorizedLessors, unauthorisedLessors: unauthorizedLessors} }, postConfig());
            setLoading(false);
            return result;
        } catch (err: any) {
            await handleSecureCatchErrors(err);
            return undefined;
        }
    };

    const doGetMachineSellerContact = async (machineId: number) => {
        setLoading(true);
        try {
            const recaptcha = await generateRecaptcha("guest");
            if (!recaptcha) return undefined;
            const result = await lessorCompanyApi.lessorCompanyMachineSellersContactMachineIdGet({ machineId: machineId }, getConfig());
            setLoading(false);
            return result;
        } catch (err: any) {
            await handleSecureCatchErrors(err);
            return undefined;
        }
    };

    const doDeleteRenterUser = async (otp: string) => {
        setLoading(true);
        try {
            const result = await renterCompanyApi.renterCompanyIdOtpDelete({id: 0, otp: otp}, getConfig());
            return result;
        } catch (err: any) {
            await handleSecureCatchErrors(err);
            return undefined;
        } finally {
            setLoading(false);
        }
    }

    const doGenerateDeleteRenterUserOTP = async () => {
        setLoading(true);
        try {
            const result = await renterCompanyApi.renterCompanyDeleteAccountOtpPost(postConfig());
            return result;
        } catch (err: any) {
            await handleSecureCatchErrors(err);
            return undefined;
        } finally {
            setLoading(false);
        }
    }

    const doGenerateDeleteLessorUserOTP = async () => {
        setLoading(true);
        try {
            const result = await lessorCompanyApi.lessorCompanyDeleteAccountOtpPost(postConfig());
            return result;
        } catch (err: any) {
            await handleSecureCatchErrors(err);
            return undefined;
        } finally {
            setLoading(false);
        }
    }

    const doDeleteLessorUserV2 = async (otp: string) => {
        setLoading(true);
        try {
            const result = await lessorCompanyApi.lessorCompanyIdOtpDelete({id: 0, otp}, getConfig()); // lessor does not need to provide id
            return result;
        } catch (err: any) {
            await handleSecureCatchErrors(err);
            return undefined;
        } finally {
            setLoading(false);
        }
    }
    const doSaleDetailViewNotification = async (machineId: number) => {
        const recaptcha = await generateRecaptcha("pageview");
        if (!recaptcha) return undefined;
        await viewApi.viewSaleMachineDetailIdGet({id: machineId});
    }

    const doSaleLessorViewNotification = async (machineId: number) => {
        const recaptcha = await generateRecaptcha("pageview");
        if (!recaptcha) return undefined;
        await viewApi.viewSaleMachineLessorIdGet({id: machineId});
    }

    const doGetSaleMachineStatic = async (machineId: number) => {
        const recaptcha = await generateRecaptcha("pageview");
        if (!recaptcha) return undefined;
        return await viewApi.viewGetSaleMachineStatisticIdGet({id: machineId}, getConfig());
    }

    return {
        doLogin,
        doLessorSignup,
        doRenterSignup,
        doResendActivationCode,
        doActivate,
        doForgotPassword,
        doResetPassword,
        doGetMachineSubcategory,
        doGetFuelTypes,
        doGetCameraResolutionTypes,
        doGetElevatorTypes,
        doGetRentalTypes,
        doGetCurrencies,
        doGetCities,
        doGetExactCities,
        doGetAttachments,
        doPostCreateMachine,
        doGetMachine,
        doGetSaleMachine,
        doSearchMachine,
        doGetMachineForRent,
        doGetBrandsByCategory,
        doDeleteMachine,
        doUpdateMachine,
        doCreateNewRequest,
        doSearchRequests,
        doGetRequestDetail,
        doUpdateRequest,
        doLogout,
        doSwitch,
        doRejectRequest,
        doApproveRequest,
        doSearchOffers,
        doGetOfferDetail,
        doGetSuitableMachinesToOffer,
        doGetPaymentTypes,
        doGetMaturityOptions,
        doPostOffer,
        doUpdateOffer,
        doAcceptOfferForm,
        doDeclineOfferForm,
        doGetRelatedOffersOfMachine,
        doGetLessorCompanyDetails,
        doGetLessorCompanyUsers,
        doGetLessorCompanyProfile,
        doSaveLessorCompanyProfile,
        doSaveLessorCompanyInfo,
        doGetCompanyDetailForRequest,
        doGetLessorCompanyDetail,
        doGetRenterCompanyDetail,
        doBlockLessorCompany,
        doBlockRenterCompany,
        doGetLessorBlocked,
        doGetRenterBlocked,
        doCreateLessorUser,
        doUpdateLessorPaymentInfo,
        doGetRenterCompanyInfo,
        doSaveRenterCompanyProfile,
        doUpdateRenterPaymentInfo,
        doGetNotificationCount,
        doGetNotifications,
        doGetAllNotifications,
        doGetRenterCompanyProfile,
        doUpdateRenterCompanyInfo,
        doGetBillingTimeOptions,
        doGetPaymentPeriod,
        doRenterRegisterWithRequest,
        doCreateNewRequestWithRegister,
        doUpdateLessorUserAgreements,
        doUpdateRenterUserAgreements,
        doUnApproveMachine,
        doUnApproveSaleMachine,
        doGetCategoryWithChildren,
        doGetMachines,
        doGetSaleMachines,
        doGetUserDetails,
        doGetMachineCommissionsForFee,
        doGetMachineCommissionsForSale,
        doGetExchangeRates,
        doActivateWithUpdate,
        doSetPassword,
        doGetLessorByMachineId,
        doRemoveSaleMachine,
        doCreateRentalFromSale,
        doCreateSaleFromRental,
        doUpdateSaleMachine,
        doAuthorizeLessorsForSale,
        doDeleteLessorUser,
        doGetMachineSellerContact,
        doGetAuthorisedLessorDetailForRequest,
        doDeleteRenterUser,
        doDeleteLessorUserV2,
        doGenerateDeleteRenterUserOTP,
        doGenerateDeleteLessorUserOTP,
        doSaleDetailViewNotification,
        doSaleLessorViewNotification,
        doGetSaleMachineStatic,
    };
};
